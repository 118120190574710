<script lang="ts">
    import type { JsonListNode } from '../../jsonNode';
    export let value: JsonListNode;
</script>

<template>
    {#if value.subtype === 'ordered'}
        <ol class="knowbl--json-list">
            <slot />
        </ol>
    {:else}
        <ul class="knowbl--json-list">
            <slot />
        </ul>
    {/if}
</template>
