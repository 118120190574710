<script lang="ts">
    import type { JsonHorizontalRuleNode } from '../../jsonNode';

    // svelte-ignore unused-export-let
    export let value: JsonHorizontalRuleNode;
</script>

<template>
    <div class="knowbl--json-horizontal-rule" />
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-horizontal-rule) {
        width: 100%;
        height: 1px;
        margin: 1em 0;
        border-bottom: 1px solid var(--knowbl-var-bubble-color-concierge-text);
    }
</style>
