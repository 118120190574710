<script lang="ts">
    import { ResponseNodeType, ListResponseNode } from '../query';
    import DomReferenceSpeechBubble from './DomReferenceSpeechBubble.svelte';
    import HtmlSpeechBubble from './HtmlSpeechBubble.svelte';
    export let value: ListResponseNode;
</script>

<template>
    {#each value.children as child, i}
        {#if child.type === ResponseNodeType.html}
            <HtmlSpeechBubble value={child} />
        {:else if child.type === ResponseNodeType.domReference}
            <DomReferenceSpeechBubble value={child} />
        {/if}

        {#if i !== value.children.length - 1}
            <div class="knowbl--list-bubble-divider" />
        {/if}
    {/each}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--list-bubble-divider) {
        width: 100%;
        height: 1px;
        margin: 1em 0;
        border-bottom: 1px solid var(--knowbl-var-bubble-color-concierge-text);
    }
</style>
