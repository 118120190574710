<script lang="ts">
    export let icon: string | null;

    const formated = () => {
        if (!icon) {
            return icon;
        }
        return icon.replace(/["']/g, '\\"');
    };
</script>

<template>
    <div class="knowbl--link-icon-container">
        {#if icon}
            <div style="--knowbl-var-icon: url('{formated()}');" class="knowbl--link-icon" />
        {:else}
            <slot />
        {/if}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--link-icon-container) {
        cursor: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-icon) {
        cursor: inherit;
        background-image: var(--knowbl-var-icon);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 75%;
        height: 75%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-icon-container) :global(svg) {
        width: 40%;
        height: 40%;
    }
</style>
