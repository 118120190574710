<script lang="ts">
    import type { Config } from '../config';
    import { JsonNodeType } from '../jsonNode';
    import type { JsonResponseNode } from '../query';
    import LinkButton from './LinkButton.svelte';
    import JsonActions from './json/JsonActions.svelte';
    import JsonCard from './json/JsonCard.svelte';
    import JsonCarousel from './json/JsonCarousel.svelte';
    import JsonHorizontalRule from './json/JsonHorizontalRule.svelte';
    import JsonImage from './json/JsonImage.svelte';
    import JsonLink from './json/JsonLink.svelte';
    import JsonList from './json/JsonList.svelte';
    import JsonParagraph from './json/JsonParagraph.svelte';
    import JsonText from './json/JsonText.svelte';
    export let value: JsonResponseNode;
    export let config: Config;
    export let latest = true;
    // optional URL that, if specified, will render a link button
    export let linkButtonUrl: string | null = null;
</script>

<template>
    <div class="knowbl--json-bubble">
        {#each value.payload as child}
            {#if child.type === JsonNodeType.carousel}
                <JsonCarousel on:action value={child} {latest} {config} />
            {:else if child.type === JsonNodeType.paragraph}
                <JsonParagraph value={child} on:link />
            {:else if child.type === JsonNodeType.text}
                <JsonText value={child} />
            {:else if child.type === JsonNodeType.list}
                <JsonList value={child} on:link />
            {:else if child.type === JsonNodeType.divider}
                <JsonHorizontalRule value={child} />
            {:else if child.type === JsonNodeType.image}
                <JsonImage value={child} {config} />
            {:else if child.type === JsonNodeType.actions}
                <JsonActions on:action value={child} {latest} />
            {:else if child.type === JsonNodeType.link}
                <JsonLink value={child} on:link />
            {:else if child.type === JsonNodeType.card}
                <JsonCard on:action value={child} {latest} {config} on:link />
            {/if}
        {/each}
        {#if linkButtonUrl}
            <LinkButton
                value={{
                    url: linkButtonUrl,
                    label: config.bubbles.contentSourceLinks.label
                }}
            />
        {/if}
    </div>
</template>

<style>
    /* Note: we need to ignore some componentry with the global color/font overrides */
    :global(#knowbl-chat-widget) :global(.knowbl--json-bubble),
    :global(#knowbl-chat-widget) :global(.knowbl--json-bubble) :global(*) {
        color: var(--knowbl-var-color-input-text);
        font-family: var(--knowbl-var-text-font-family);
    }
</style>
