<script lang="ts">
    import type { JsonTextNode } from '../../jsonNode';
    export let value: JsonTextNode;
</script>

<template>
    <p class="knowbl--json-text">{value.value}</p>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-text) {
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        overflow-wrap: break-word;
    }
</style>
