<script lang="ts">
    import { type JsonParagraphNode, type JsonListNode, JsonNodeType } from '../../jsonNode';
    import JsonParagraph from './JsonParagraph.svelte';
    import JsonListWrapper from './JsonListWrapper.svelte';
    export let value: JsonListNode;

    const heading = {
        type: 'hierarchy_paragraph',
        value: value.header,
        modifiers: value.modifiers
    } as JsonParagraphNode;
</script>

<template>
    {#if heading.value}
        <li><JsonParagraph value={heading} /></li>
    {/if}
    <JsonListWrapper {value}>
        {#each value.children as child}
            {#if child.type === JsonNodeType.paragraph}
                <li><JsonParagraph value={child} on:link /></li>
            {:else if child.type === JsonNodeType.list}
                <svelte:self value={child} />
            {/if}
        {/each}
    </JsonListWrapper>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-list),
    :global(#knowbl-chat-widget) :global(.knowbl--json-list),
    :global(#knowbl-chat-widget) :global(.knowbl--json-list) :global(li) {
        margin-inline-start: 0.4em;
        padding-inline-start: 0.1em;
        padding-block: 0.1em;
        margin-top: 0;
        margin-bottom: 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-list) :global(p),
    :global(#knowbl-chat-widget) :global(.knowbl--json-list) :global(p),
    :global(#knowbl-chat-widget) :global(.knowbl--json-list) :global(li) {
        padding-top: 0;
        padding-bottom: 0;
    }
</style>
