<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { LinkTabHint } from '../jsonNode';
    export let value: { url: string; label?: string; tab?: LinkTabHint | null };

    const parsedURL = new URL(value.url, window.location.href);
    const sameOrigin = window.location.origin === parsedURL.origin;
    const formattedURL = parsedURL.hostname + parsedURL.pathname + parsedURL.search + parsedURL.hash;
    let target: string | null = null;
    if (value.tab === 'blank') {
        target = '_blank';
    } else if (value.tab === 'self') {
        target = '_self';
    }

    const dispatch = createEventDispatcher();
</script>

<template>
    <a
        class="knowbl--link-button"
        href={parsedURL.toString()}
        target={target ?? (sameOrigin ? '_self' : '_blank')}
        rel={sameOrigin ? undefined : 'noreferrer'}
        on:click={() => dispatch('link', { url: parsedURL })}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="knowbl--link-button-svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
        >
            {#if value.tab ? value.tab === 'self' : sameOrigin}
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="currentColor"
                    d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
            {:else}
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="currentColor"
                    d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
            {/if}
        </svg>
        <div class="knowbl--link-button-content">
            {#if value.label}
                <p class="knowbl--link-button-label">{value.label}</p>
            {/if}
            <p class="knowbl--link-button-url">{formattedURL}</p>
        </div>
    </a>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--link-button) :global(*) {
        pointer-events: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-button):hover {
        opacity: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-button-content) {
        flex: 1;
        min-width: 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-button-label) {
        font-weight: bold;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-button-url) {
        opacity: 70%;
        font-size: 12px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-button) {
        opacity: 90%;
        display: flex;
        align-items: center;
        gap: 1em;
        cursor: pointer;
        overflow-wrap: break-word;
        background-color: #edeff3;
        padding: 0.5em 1em;
        border-radius: 7.5px;
        transition: opacity 0.2s ease;
        margin: 0.5em 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--link-button-svg) {
        flex-shrink: 0;
        height: 22px;
        width: 22px;
    }
</style>
