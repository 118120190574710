/** Custom Svelte transitions which make HTML elements a lot more interesting */

import { cubicIn, cubicOut } from 'svelte/easing';

/** Blurs the backdrop in/out. */
export const blurBackdrop = (node: Element, { duration = 1, amount = 15 }) => {
    return {
        duration,
        css: (t: number) => {
            const cin = cubicIn(t);
            const cout = cubicOut(t);
            return `
                opacity: ${cin};
                backdrop-filter: blur(${cout * amount}px);
                -webkit-backdrop-filter: blur(${cout * amount}px);`;
        }
    };
};

/** Types out the direct child of a node at a constant speed. */
export const typewriter = (node: Element, { speed = 2 }) => {
    const text = node.textContent;
    const duration = text.length / (speed * 0.01);
    return {
        duration,
        tick: (t: number) => {
            const i = Math.trunc(text.length * t);
            node.textContent = text.slice(0, i);
        }
    };
};
