import { BridgeConsumeType, type Bridge } from './bridge';
import { resolveHost, type Config } from './config';

/**
 * Builds an analytics URL using the provided configuration and path.
 *
 * @param {Config} config - The configuration object containing the necessary properties.
 * @param {string} path - The path to append to the URL.
 * @return {string} The complete analytics URL.
 */
const buildAnalyticsURL = (config: Config, path: string): string => {
    path = config.queryEndpoint.endsWith('/') ? path : `/${path}`; // ensure path starts with a forward slash
    return [resolveHost(config.host), config.analyticsEndpoint, path].join('');
};

/**
 * Handles an analytics error and sends an error message to the bridge.
 *
 * @param {Error | unknown} e - The error object or unknown value.
 * @param {Bridge} bridge - The bridge object used to send the error message beyond knowbl chat.
 * @return {void} This function does not return anything.
 */
const handleAnalyticsError = (e: Error | unknown, bridge: Bridge) => {
    let errorMessage: string;

    if (e instanceof Error) {
        errorMessage = `Error posting thumb feedback - ${e.name}: ${e.message}. Full error: ${JSON.stringify(e)}`;
    } else {
        errorMessage = 'Error posting thumb feedback';
    }
    bridge._sendConsumable(BridgeConsumeType.error, { message: errorMessage });
};

/**
 * Posts feedback to the analytics feedback endpoint.
 *
 * @param {Config} config - The configuration object.
 * @param {string} userId - The ID of the user providing the feedback.
 * @param {string} lastTurnId - The ID of the last turn in the conversation.
 * @param {string} appKey - The key identifying the application.
 * @param {number} score - The feedback score provided by the user.
 * @return {Promise<void>} - A promise that resolves when the feedback is posted successfully.
 */
export const postFeedback = async (
    config: Config,
    bridge: Bridge,
    userId: string,
    lastTurnId: string,
    appKey: string,
    score: number
) => {
    const integrationKey = config.integrationKey ? config.integrationKey : 'no_integration_key';
    const url = buildAnalyticsURL(config, `/tenant/${config.workspaceId}/feedback`);

    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: userId,
                turn_id: lastTurnId,
                app_key: appKey,
                integration_key: integrationKey,
                score: score
            })
        });
    } catch (e) {
        handleAnalyticsError(e, bridge);
    }
};
