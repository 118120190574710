<script lang="ts">
    import { ActionType } from '../../action';
    import type { JsonActionsNode } from '../../jsonNode';
    import JsonEventAction from './action/JsonEventAction.svelte';
    import JsonQueryAction from './action/JsonQueryAction.svelte';
    export let value: JsonActionsNode;
    export let latest: boolean;
</script>

<template>
    <div class="knowbl--json-actions">
        {#each value.actions as action}
            {#if action.type === ActionType.event}
                <JsonEventAction on:action value={action} />
            {:else if action.type === ActionType.query}
                <JsonQueryAction on:action value={action} disabled={!latest} />
            {/if}
        {/each}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-actions) {
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5em;
    }
</style>
