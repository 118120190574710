<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import { fly, fade } from 'svelte/transition';
    import { backOut } from 'svelte/easing';
    import LinkIcon from './LinkIcon.svelte';
    import type { Callout } from '../bridge';

    export let icon: string;
    export let callout: Callout;

    const dispatch = createEventDispatcher();

    const onOpen = () => {
        dispatch('open');
    };

    const closeCallout = () => {
        callout = null;
    };
</script>

<template>
    {#if callout !== null}
        <div class="knowbl--app-callout" in:fly|local={{ y: -50, duration: 600, easing: backOut }} out:fade|local>
            <div class="knowbl--app-callout-container">
                <button class="knowbl--app-callout-close" aria-label="Close callout" on:click={closeCallout}>
                    <svg class="knowbl--app-callout-close-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                        <path
                            fill="currentColor"
                            d="M9 1H8L5 4 2 1a1 1 0 0 0-1 1l3 3-3 3a1 1 0 0 0 1 1l3-3 3 3a1 1 0 0 0 1-1L6 5l3-3V1Z"
                        />
                    </svg>
                </button>
                <div class="knowbl--app-callout-text">{callout.text}</div>
            </div>
            <div class="knowbl--app-callout-caret" />
        </div>
    {/if}
    <div class="knowbl--brand-icon-padding">
        <button class="knowbl--brand-icon-container" aria-label="Open widget" on:click={onOpen}>
            <LinkIcon {icon}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 47">
                    <path
                        fill="#FFF"
                        d="M45 0H5C2.243 0 0 2.243 0 5v26.667c0 2.756 2.243 5 5 5h5V45a1.669 1.669 0 0 0 2.708 1.302l12.044-9.635H45c2.757 0 5-2.244 5-5V5c0-2.757-2.243-5-5-5ZM25 23.333H11.667a1.666 1.666 0 1 1 0-3.333H25a1.666 1.666 0 1 1 0 3.333Zm13.333-6.666H11.667a1.666 1.666 0 1 1 0-3.334h26.666a1.666 1.666 0 1 1 0 3.334Z"
                    />
                </svg>
            </LinkIcon>
        </button>
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout) {
        max-width: 250px;
        min-width: 250px;
        margin-left: 1em;
        margin-right: 54px;
        margin-bottom: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        place-items: end;
        pointer-events: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout-text) {
        color: white;
        font-size: 12px;
        font-family: var(--knowbl-var-text-font-family);
        background-color: var(--knowbl-var-window-accent-color);
        border-radius: 7.5px;
        border-bottom-right-radius: 0;
        padding: 1em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout-caret) {
        width: 0;
        height: 0;
        transform: translateY(-1px);
        border-top: 10px solid var(--knowbl-var-window-accent-color);
        border-left: 15px solid transparent;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--brand-icon-padding) {
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        position: relative;
        float: right;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--brand-icon-container) {
        height: 60px;
        width: 60px;
        background-color: var(--knowbl-var-window-open-button-color);
        border-radius: 50%;
        overflow: hidden;
        border: none;
        box-shadow: 0px 0px 20px 1px var(--knowbl-var-window-open-button-color);
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout-close) {
        height: 20px;
        width: 20px;
        background-color: rgb(240, 240, 240);
        border-radius: 100%;
        overflow: hidden;
        transform: translateY(12px) translateX(-9px);
        border: 1px solid var(--knowbl-var-window-accent-color);
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout-close:hover) {
        background-color: rgb(230, 230, 230);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout-close:active) {
        background-color: rgb(210, 210, 210);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-callout-close-svg) {
        height: 60%;
        display: block;
        margin-left: 3.75px;
        color: var(--knowbl-var-window-accent-color);
    }
</style>
