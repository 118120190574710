<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { Bubble } from '../bubble';
    import type { QuerySubResponseSource } from '../query';

    export let source: QuerySubResponseSource | undefined;
    export let text: string | undefined;
    export let bubble: Bubble | null;

    let subjectURL: URL | undefined = undefined;
    let sameOrigin = false;
    $: {
        subjectURL = source?.location ? new URL(source.location, window.location.href) : undefined;
        sameOrigin = subjectURL ? window.location.origin === subjectURL.origin : false;
    }

    const dispatch = createEventDispatcher();
    const onClick = (e: Event) => {
        e.stopPropagation();
        dispatch('link', { url: subjectURL, bubble });
    };
</script>

<template>
    <div class="knowbl--subject-link-container">
        <div class="knowbl--subject-link-texts">
            {#if source?.name}
                <div class="knowbl--subject-link-source">{source.name}</div>
            {/if}
            {#if text}
                <div class="knowbl--subject-link-text">{text}</div>
            {/if}
        </div>
        {#if subjectURL}
            <a
                class="knowbl--subject-link"
                data-testid="subject-link"
                href={subjectURL.toString()}
                target={sameOrigin ? '_self' : '_blank'}
                rel={sameOrigin ? undefined : 'noreferrer'}
                on:click={onClick}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        fill-rule="evenodd"
                        d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z"
                        clip-rule="evenodd"
                    />
                </svg>
            </a>
        {/if}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link) {
        color: #1f43e4;
        text-decoration: none;
        flex-shrink: 1;
        align-self: flex-start;
        border-radius: 5px;
        padding: 0.25em;
        background-color: rgba(0, 0, 0, 0.07);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link):hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link-source) {
        color: gray;
        font-style: italic;
        font-size: 12px;
        font-family: var(--knowbl-var-text-font-family);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link-texts) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.25em;
        min-width: 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link-container) {
        display: flex;
        justify-content: space-between;
        gap: 1em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link-text) {
        color: black;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: var(--knowbl-var-text-font-family);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--subject-link) :global(svg) {
        height: 22px;
        width: 22px;
    }
</style>
