<script lang="ts">
    import JsonCardParagraphList from './card/JsonCardParagraphList.svelte';
    import JsonCardImagePane from './card/JsonCardImagePane.svelte';
    import JsonCardActions from './card/JsonCardActions.svelte';

    import type { Config } from '../../config';
    import type { JsonCardChild } from '../../jsonNode';
    import {
        JsonActionsNode,
        JsonCardNode,
        JsonImageNode,
        JsonLinkNode,
        JsonNodeType,
        JsonParagraphNode
    } from '../../jsonNode';

    export let value: JsonCardNode;
    export let config: Config;
    export let latest: boolean;

    const findChildrenForType = (node: JsonCardNode, type: JsonNodeType): JsonCardChild[] => {
        return node.children.filter((child) => child.type === type);
    };

    const images = findChildrenForType(value, JsonNodeType.image) as JsonImageNode[];
    const paragraphs = findChildrenForType(value, JsonNodeType.paragraph) as JsonParagraphNode[];
    const maybeLink = findChildrenForType(value, JsonNodeType.link)[0] as JsonLinkNode | undefined;
    const maybeActions = findChildrenForType(value, JsonNodeType.actions)[0] as JsonActionsNode | undefined;
</script>

<template>
    <div class="knowbl--card">
        <JsonCardImagePane {maybeLink} {images} {config} on:link />

        <JsonCardParagraphList value={paragraphs} />

        <JsonCardActions on:action value={maybeActions} {latest} dispatch={undefined} />
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--card) {
        background-color: white;
        color: black;
        font-size: 12px;
        padding: 24px;
    }
</style>
