import type { Writable } from 'svelte/store';
import { writable } from 'svelte/store';

const HEIGHT_EPSILON = 0.01;

export class Layout {
    private _vh: Writable<number>;
    private _hw: Writable<number>;
    private _isChildOfBody: boolean;
    private _observer: ResizeObserver;

    public constructor() {
        this._vh = writable(0);
        this._hw = writable(0);
    }

    public onMount(isChildOfBody: boolean, parent: HTMLElement) {
        this._isChildOfBody = isChildOfBody;
        this._hw.set(this.width());
        this._observer = new ResizeObserver(() => {
            this._hw.set(this.width());
            if (this._isChildOfBody) {
                this._vh.set(this.height());
            }
        });
        this._observer.observe(parent);
        if (this._isChildOfBody) {
            this._vh.set(this.height());
            window.addEventListener('scroll', () => {
                document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
            });
        }
    }

    get vh() {
        return this._vh;
    }

    get hw() {
        return this._hw;
    }

    public disableBodyScroll = () => {
        if (!this._isChildOfBody) {
            return;
        }
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const width = document.body.clientWidth;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}`;
        document.body.style.width = `${width}px`;
    };

    public resetBodyScroll = () => {
        if (!this._isChildOfBody) {
            return;
        }
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };

    private height = () => {
        if (!this._isChildOfBody) {
            return 0;
        }
        return window.innerHeight * 0.01 + HEIGHT_EPSILON;
    };

    private width = () => {
        return window.innerWidth;
    };
}
