<script lang="ts">
    import { JsonParagraphNode, nestModifiers } from '../../../jsonNode';
    import JsonModifiers from '../JsonModifiers.svelte';

    export let value: JsonParagraphNode;

    const nodes = nestModifiers(value.value, value.modifiers ?? []);
</script>

<template>
    <p class="knowbl--card-paragraph">
        {#if nodes.length}
            <JsonModifiers {nodes} />
        {:else}
            {@html value.value}
        {/if}
    </p>
</template>

<style>
    /* TODO replace these values with variables defined in the App */
    :global(#knowbl-chat-widget) :global(.knowbl--card-paragraph) {
        color: black;
        font-size: 13px;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--card-paragraph) :global(*) {
        color: black;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--card-paragraph) :global(a) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        text-decoration: underline;
        cursor: pointer;
    }
</style>
