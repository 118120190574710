<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { JsonLinkModifier, JsonModifier } from '../../../jsonNode';
    export let modifier: JsonModifier;

    const castedModifier = modifier as JsonLinkModifier;
    const parsedURL = new URL(castedModifier.url, window.location.href);
    const sameOrigin = window.location.origin === parsedURL.origin;
    let target: string | null = null;
    if (castedModifier.tab === 'blank') {
        target = '_blank';
    } else if (castedModifier.tab === 'self') {
        target = '_self';
    }

    const dispatch = createEventDispatcher();
</script>

<template>
    <a
        class="knowbl--json-anchor"
        href={parsedURL.toString()}
        target={target ?? (sameOrigin ? '_self' : '_blank')}
        rel={sameOrigin ? undefined : 'noreferrer'}
        on:click={() => dispatch('link', { url: parsedURL })}
    >
        <slot />
    </a>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-anchor) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        text-decoration: underline;
        cursor: pointer;
    }
</style>
