<script lang="ts">
    import { resolveHost, type Config } from '../../config';
    import type { JsonImageNode } from '../../jsonNode';
    export let value: JsonImageNode;
    export let config: Config;

    const url = value.id ? `/image/i/${value.id}` : value.url;
    const src = url.startsWith('/') && !url.startsWith('//') ? `${resolveHost(config.host)}${url}` : url;
</script>

<template>
    <img class="knowbl--json-image" {src} alt={value.alt} />
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-image) {
        width: 100%;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        max-height: 300px;
        object-fit: contain;
    }
</style>
