<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { QueryAction } from '../../../action';
    import JsonActionButton from './JsonActionButton.svelte';
    export let value: QueryAction;
    export let disabled = false;

    const dispatch = createEventDispatcher();
    const handleAction = () => {
        dispatch('action', { action: value });
    };
</script>

<template>
    <JsonActionButton on:action={handleAction} label={value.label} {disabled} />
</template>
