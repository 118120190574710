<script lang="ts">
    import type { JsonParagraphNode } from '../../../jsonNode';
    import JsonCardParagraph from './JsonCardParagraph.svelte';

    export let value: JsonParagraphNode[];
    const paragraphs = [...value];

    // this is the number of additional paragraphs to show. If there are more then a "Show More"/"Show Less" button
    // will be displayed
    const numParagraphsToShow = 3;
    const header = paragraphs.shift();
    const alwaysVisibleParagraphs = paragraphs.slice(0, numParagraphsToShow);
    const moreParagraphs = paragraphs.slice(numParagraphsToShow);

    let showMoreParagraphs = false;
    let moreButtonText = 'Show More';

    const toggleMore = () => {
        showMoreParagraphs = !showMoreParagraphs;
        moreButtonText = showMoreParagraphs ? 'Show Less' : 'Show More';
    };
</script>

<template>
    {#if value.length}
        <h1 class="knowbl--card-paragraph-header">{header.value}</h1>

        {#each alwaysVisibleParagraphs as paragraph}
            <JsonCardParagraph value={paragraph} />
        {/each}
        {#if showMoreParagraphs}
            {#each moreParagraphs as paragraph}
                <JsonCardParagraph value={paragraph} />
            {/each}
        {/if}
        {#if moreParagraphs.length}
            <button class="knowbl--card-paragraph-more" aria-label={moreButtonText} on:click={toggleMore}
                >{moreButtonText}</button
            >
        {/if}
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--card-paragraph-header) {
        padding: 2px 0 2px 0;
        color: black;
        font-size: 24px;
        font-weight: bold;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-paragraph-more) {
        color: black;
        font-size: 0.8em;
        text-decoration: underline;
        cursor: pointer;
    }
</style>
