<script lang="ts">
    import type { Config } from '../config';

    import { createEventDispatcher } from 'svelte';
    import LinkIcon from './LinkIcon.svelte';

    const dispatch = createEventDispatcher();

    export let conciergeName: string;
    export let convStart: string;
    export let maximized: boolean;
    export let config: Config;

    const startText = config.type === 'search' ? 'Search' : 'Conversation';

    const onResize = () => {
        dispatch('resize');
    };
    const onClose = () => {
        dispatch('close');
    };
</script>

<template>
    <div class="knowbl--top-bar-container">
        <div class="knowbl--top-bar-dot">
            <LinkIcon icon={config.logos.brand}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFF">
                    <path
                        fill-rule="evenodd"
                        d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                        clip-rule="evenodd"
                    />
                </svg>
            </LinkIcon>
        </div>
        <div class="knowbl--top-bar-text">
            <p class="knowbl--top-bar-title">{conciergeName}</p>
            <p class="knowbl--top-bar-subtitle">
                {startText} started at {convStart}
            </p>
        </div>
        <div class="knowbl--top-bar-padding" />
        <button
            type="button"
            aria-label="Resize top bar"
            class="knowbl--top-bar-button knowbl--top-bar-resize"
            on:click={onResize}
            class:knowbl--top-bar-left={maximized}
            class:knowbl--top-bar-right={!maximized}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="knowbl--top-bar-svg"
            >
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
            </svg>
        </button>
        <button type="button" aria-label="Close chat" class="knowbl--top-bar-button" on:click={onClose}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="knowbl--top-bar-svg"
            >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                    fill="currentColor"
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                />
            </svg>
        </button>
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-text) {
        font-family: var(--knowbl-var-text-font-family);
        margin-left: 0.5em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-left) {
        transition: transform 0.5s;
        transform: rotateY(180deg);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-right) {
        transition: transform 0.5s;
        transform: rotateY(0deg);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-title) {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 23px;
        text-transform: uppercase;
        color: var(--knowbl-var-text-color-title);
        font-family: var(--knowbl-var-text-font-family);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-subtitle) {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.8px;
        line-height: 18px;
        font-family: var(--knowbl-var-text-font-family);
        color: var(--knowbl-var-text-color-subtitle);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-padding) {
        flex: 1;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-container) {
        display: flex;
        margin: 1em;

        /* Prevent clipping on Safari */
        transform: translateZ(1000px);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-button) {
        width: 45px;
        height: 45px;
        color: var(--knowbl-var-close-icon-color);
        background-color: rgba(0, 0, 0, 0);
        border: none;
        margin-right: 0;
        margin-left: auto;
        display: block;
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-button:hover) {
        color: var(--knowbl-var-close-icon-hover-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-svg) {
        height: 100%;
        width: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--top-bar-dot) {
        height: 40px;
        width: 40px;
        background-color: var(--knowbl-var-window-open-button-color);
        border-radius: 50%;
        overflow: hidden;
    }
    @media only screen and (max-width: 576px) {
        :global(#knowbl-chat-widget) :global(.knowbl--top-bar-resize) {
            display: none;
        }
    }
</style>
