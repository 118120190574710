<script lang="ts">
    import type { JsonCarouselNode } from '../../jsonNode';
    import type { Config } from '../../config';
    import JsonCard from './JsonCard.svelte';

    export let value: JsonCarouselNode;
    export let latest: boolean;
    export let config: Config;

    let activeIndex = 0;

    const previousClicked = () => {
        activeIndex -= 1;
        if (activeIndex < 0) {
            activeIndex = value.children.length - 1;
        }
    };

    const nextClicked = () => {
        activeIndex += 1;
        if (activeIndex >= value.children.length) {
            activeIndex = 0;
        }
    };

    const displayButtons = value.children.length > 1;
</script>

<template>
    <div class="knowbl--chat-carousel-container">
        {#if displayButtons}
            <div class="knowbl--chat-carousel-button-container">
                <button
                    aria-label="Show previous"
                    on:click={previousClicked}
                    class="knowbl--chat-carousel-button knowbl--chat-carousel-button-previous"
                >
                    &lt;
                </button>
            </div>
        {/if}
        {#each value.children as child, index}
            {#if activeIndex === index}
                <div class:knowbl--chat-carousel-subcontainer={displayButtons}>
                    <JsonCard value={child} {config} {latest} />
                </div>
            {/if}
        {/each}
        {#if displayButtons}
            <div class="knowbl--chat-carousel-button-container">
                <button
                    aria-label="Show next"
                    on:click={nextClicked}
                    class="knowbl--chat-carousel-button knowbl--chat-carousel-button-next"
                >
                    &gt;
                </button>
            </div>
        {/if}
    </div>

    <div class="knowbl--chat-carousel-results">
        Results {activeIndex + 1} of {value.children.length}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-container) {
        display: flex;
        align-items: center;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-subcontainer) {
        margin-left: -30px;
        margin-right: -30px;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-button-container) {
        z-index: 1;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-button) {
        size: 14px;
        cursor: pointer;
        background-color: black;
        color: lightgray;
        border-radius: 100%;
        height: 32px;
        width: 32px;
        text-align: center;
        border-color: white;
        border-width: 1px;
        border-style: solid;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-button-previous) {
        margin-right: 10px;
        margin-left: -10px;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-button-next) {
        margin-left: 10px;
        margin-right: -10px;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--chat-carousel-results) {
        text-align: center;
        color: #eee;
        padding-top: 8px;
        font-size: 10px;
    }
</style>
