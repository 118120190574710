<script lang="ts">
    import { onMount } from 'svelte';
    import { fly } from 'svelte/transition';

    import type { DropdownOption } from '../dropdown';

    import { createFocusTrap, type FocusTrap } from 'focus-trap';

    export let options: DropdownOption[];
    export let disabled: boolean;
    export let direction: 'up' | 'down';

    let focusElem: HTMLDivElement;
    let buttonElem: HTMLButtonElement;
    let trap: FocusTrap;

    let opened = false;
    let closingWithButton = false;
    const flyY = direction === 'up' ? -25 : 25;

    const handleOpen = () => {
        if (closingWithButton) {
            closingWithButton = false;
            return;
        }
        opened = true;
        trap.activate();
    };

    const handleClose = () => {
        opened = false;
        trap.deactivate();
    };

    const handleClick = (option: DropdownOption) => {
        option.callback();
        handleClose();
    };

    const onDeactivate = () => {
        opened = false;
    };

    onMount(() => {
        trap = createFocusTrap(focusElem, {
            clickOutsideDeactivates: function (e) {
                if (e.target === buttonElem) {
                    closingWithButton = true;
                }
                return true;
            },
            onDeactivate: onDeactivate,
            fallbackFocus: buttonElem
        });
    });
</script>

<template>
    <div class="knowbl--dropdown">
        <div bind:this={focusElem}>
            {#if opened}
                <div
                    class="knowbl--dropdown-container {direction === 'up'
                        ? 'knowbl--dropdown-container-up'
                        : 'knowbl--dropdown-container-down'}"
                    transition:fly|local={{ y: flyY, duration: 250 }}
                >
                    <div
                        class="knowbl--dropdown-menu {direction === 'up'
                            ? 'knowbl--dropdown-menu-up'
                            : 'knowbl--dropdown-menu-down'}"
                    >
                        <div class="knowbl--dropdown-list">
                            {#each options as option}
                                {#if !option.hidden}
                                    <button
                                        class="knowbl--dropdown-menu-button"
                                        aria-label="Select option {option.text}"
                                        on:click={() => handleClick(option)}>{option.text}</button
                                    >
                                {/if}
                            {/each}
                        </div>
                    </div>
                    <div
                        class="knowbl--dropdown-menu-pointer {direction === 'up'
                            ? 'knowbl--dropdown-menu-pointer-up'
                            : 'knowbl--dropdown-menu-pointer-down'}"
                    />
                </div>
            {/if}
        </div>
        <button
            type="button"
            class="knowbl--dropdown-button"
            aria-label="Show more options"
            {disabled}
            on:click={handleOpen}
            bind:this={buttonElem}
        >
            <svg
                class="knowbl--dropdown-button-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="currentColor"
            >
                <path
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
            </svg>
        </button>
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown) {
        position: relative;
        flex-shrink: 0;
        margin: auto 0;
        height: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-button) {
        width: 25px;
        height: 100%;
        border-left: none;
        border-right: none;
        color: var(--knowbl-var-window-accent-light-color);
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-button-svg) {
        width: 100%;
        height: 100%;
        display: block;
        margin-left: 0;
        pointer-events: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-button:hover:not([disabled])) {
        color: var(--knowbl-var-window-accent-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-button:disabled) {
        cursor: not-allowed;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-container) {
        position: absolute;
        z-index: 1;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu) {
        width: 200px;
        margin: 0;
        border-radius: 10px;
        position: absolute;
        border: 2px solid black;
        background-color: white;

        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-up) {
        transform: translateY(-100%) translateX(-89%) translateY(-5px);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-down) {
        transform: translateY(54px) translateX(-88%);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-pointer) {
        width: 0;
        height: 0;
        position: absolute;

        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-pointer-up) {
        transform: translateY(-6px) translateX(7.5px);
        border-top: 5px solid black;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-pointer-down) {
        transform: translateY(50px) translateX(8.25px);
        border-bottom: 5px solid black;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-list) {
        display: flex;
        flex-direction: column;
        max-height: 250px;
        overflow-y: auto;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-button) {
        padding: 2em;
        padding-top: 1em;
        padding-bottom: 1em;
        border: 1px solid black;
        border-left: none;
        border-right: none;
        color: black;
        font-family: var(--knowbl-var-text-font-family);
        text-align: center;
        font-size: 12px;
        white-space: nowrap;
        position: relative;
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-button:hover) {
        background-color: rgb(240, 240, 240);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-button:first-child) {
        border-top: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--dropdown-menu-button:last-child) {
        border-bottom: none;
    }
</style>
