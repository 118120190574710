import type { Action } from './action';

export enum JsonNodeType {
    paragraph = 'hierarchy_paragraph',
    divider = 'hierarchy_horizontal_rule',
    image = 'hierarchy_image',
    list = 'hierarchy_list',
    text = 'hierarchy_text',
    actions = 'hierarchy_actions',
    link = 'hierarchy_link',
    card = 'hierarchy_card',
    carousel = 'hierarchy_carousel',
    cutoff = 'hierarchy_cutoff'
}

export type JsonNode =
    | JsonParagraphNode
    | JsonImageNode
    | JsonHorizontalRuleNode
    | JsonListNode
    | JsonTextNode
    | JsonActionsNode
    | JsonLinkNode
    | JsonCardNode
    | JsonCarouselNode
    | JsonCutoffNode;

export class JsonParagraphNode {
    type: 'hierarchy_paragraph';
    value: string;
    modifiers: JsonModifier[];
}

export class JsonTextNode {
    type: 'hierarchy_text';
    value: string;
}

export type LinkTabHint = 'self' | 'blank';

export class JsonLinkNode {
    type: 'hierarchy_link';
    url: string;
    label: string;
    tab?: LinkTabHint | null = null;
}

export class JsonHorizontalRuleNode {
    type: 'hierarchy_horizontal_rule';
}

export class JsonCutoffNode {
    type: 'hierarchy_cutoff';
}

export class JsonImageNode {
    type: 'hierarchy_image';
    id: string;
    url: string;
    alt: string;
}

export class JsonListNode {
    type: 'hierarchy_list';
    subtype: 'ordered' | 'unordered';
    header: string;
    modifiers: JsonModifier[];
    children: Array<JsonNode>;
}

export class JsonActionsNode {
    type: 'hierarchy_actions';
    actions: Action[];
}

export type JsonCardChild = JsonActionsNode | JsonParagraphNode | JsonLinkNode | JsonImageNode;

export class JsonCardNode {
    type: 'hierarchy_card';
    children: Array<JsonCardChild>;
}

export class JsonCarouselNode {
    type: 'hierarchy_carousel';
    children: Array<JsonCardNode>;
}

export type JsonModifier = JsonSimpleModifier | JsonLinkModifier | JsonImageModifer;

export class JsonSimpleModifier {
    type: 'bold' | 'italic' | 'underline' | 'strike' | 'code' | 'subscript' | 'superscript' | 'empty';
    start: number;
    end: number;
}

export class JsonLinkModifier {
    type: 'link';
    start: number;
    end: number;
    url: string;
    tab?: LinkTabHint | null = null;
}

export class JsonImageModifer {
    type: 'image';
    start: number;
    end: null;
    src: string;
    alt?: string;
    height?: number;
    width?: number;
}

export class ModifierNode {
    constructor(public modifier: JsonModifier, public text: string = '', public children: ModifierNode[] = []) {}
}

const modifierComparator = (a: JsonModifier, b: JsonModifier) =>
    a.start - b.start ||
    (b.end ?? Number.MAX_SAFE_INTEGER) - (a.end ?? Number.MAX_SAFE_INTEGER) ||
    a.type.localeCompare(b.type);
export const nestModifiers = (text: string, modifiers: JsonModifier[]): ModifierNode[] => {
    const roots: ModifierNode[] = [];
    const stack: ModifierNode[] = [];

    const handleModifierNode = (node: ModifierNode) => {
        if (stack.length === 0) {
            roots.push(node);
            stack.push(node);
            return;
        }

        const parent = stack[stack.length - 1];
        if (parent.modifier.end >= node.modifier.start) {
            node.modifier.end = Math.min(parent.modifier.end, node.modifier.end);
            parent.children.push(node);
            stack.push(node);
        } else {
            stack.pop();
            handleModifierNode(node);
        }
    };

    for (const modifier of modifiers.sort(modifierComparator)) {
        handleModifierNode(new ModifierNode(modifier));
    }

    const createFill = (start: number, end: number) =>
        new ModifierNode({ type: 'empty', start, end }, text.substring(start, end));
    const isZeroWidthNode = (node: ModifierNode) => node.modifier.end === null || node.modifier.end === undefined;

    const fillModifiers = (nodes: ModifierNode[], start: number, end: number) => {
        const filledNodes: ModifierNode[] = [];
        nodes.forEach((node, index) => {
            if (index === 0 && node.modifier.start > start) {
                filledNodes.push(createFill(start, node.modifier.start));
            }

            if (isZeroWidthNode(node)) {
                node.text = '';
            } else {
                node.text = text.substring(node.modifier.start, node.modifier.end + 1);
            }
            filledNodes.push(node);
            if (node.children.length) {
                node.children = fillModifiers(node.children, node.modifier.start, node.modifier.end);
            }

            if (index < nodes.length - 1) {
                const next = nodes[index + 1];
                if (!isZeroWidthNode(node) && node.modifier.end < next.modifier.start) {
                    filledNodes.push(createFill(node.modifier.end + 1, next.modifier.start));
                }
            }

            if (index === nodes.length - 1 && node.modifier.end < end) {
                filledNodes.push(createFill(isZeroWidthNode(node) ? node.modifier.start : node.modifier.end + 1, end));
            }
        });
        return filledNodes;
    };

    return fillModifiers(roots, 0, text.length);
};
