<script lang="ts">
    import { createEventDispatcher } from 'svelte';

    export let label: string;
    export let disabled = false;

    const dispatch = createEventDispatcher();

    const handleClick = () => {
        dispatch('action');
    };
</script>

<template>
    <button class="knowbl--json-action-button" aria-label={label} {disabled} on:click={handleClick}>{label}</button>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-action-button) {
        font-size: 14px;
        padding: 0.5em;
        padding-left: 1em;
        padding-right: 1em;
        cursor: pointer;
        border-radius: 10px;
        border: 2px solid var(--knowbl-var-window-accent-color);
        background-color: var(--knowbl-var-window-accent-light-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-action-button:hover:not([disabled])) {
        border: 2px solid var(--knowbl-var-window-accent-color);
        background-color: var(--knowbl-var-window-accent-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-action-button:disabled) {
        cursor: not-allowed;
        border: 2px solid var(--knowbl-var-window-accent-color);
        background-color: var(--knowbl-var-window-accent-light-color);
        opacity: 50%;
    }
</style>
