<script lang="ts">
    import type { JsonModifier } from '../../../jsonNode';

    // svelte-ignore unused-export-let
    export let modifier: JsonModifier;
</script>

<template>
    <code class="knowbl--json-code"><slot /></code>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-code),
    :global(#knowbl-chat-widget) :global(.knowbl--json-code) :global(*) {
        font-family: monospace, monospace;
        white-space: nowrap;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-code) {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 0.2em;
        padding: 4px;
    }
</style>
