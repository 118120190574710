<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { JsonLinkNode } from '../../../jsonNode';

    export let value: JsonLinkNode | undefined;
    let parsedURL: URL | undefined = undefined;
    let sameOrigin = false;
    $: {
        parsedURL = value?.url ? new URL(value.url, window.location.href) : undefined;
        sameOrigin = window.location.origin === parsedURL?.origin;
    }
    const dispatch = createEventDispatcher();
</script>

<template>
    {#if parsedURL}
        <div class="knowbl--card-link">
            <a
                href={parsedURL.toString()}
                target={sameOrigin ? '_self' : '_blank'}
                rel={sameOrigin ? undefined : 'noreferrer'}
                on:click={() => dispatch('link', { url: parsedURL })}
            >
                <svg
                    style="height: 16px; width: 16px; color: black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="black"
                    class="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                </svg>
            </a>
        </div>
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--card-link) {
        padding-left: 2px;
        padding-top: 8px;
    }
</style>
