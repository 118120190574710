<script lang="ts">
    import type { ModifierNode } from '../../jsonNode';
    import JsonBoldModifier from './modifier/JsonBoldModifier.svelte';
    import JsonCodeModifier from './modifier/JsonCodeModifier.svelte';
    import JsonEmptyModifier from './modifier/JsonEmptyModifier.svelte';
    import JsonItalicModifier from './modifier/JsonItalicModifier.svelte';
    import JsonLinkModifier from './modifier/JsonLinkModifier.svelte';
    import JsonImageModifier from './modifier/JsonImageModifier.svelte';
    import JsonStrikeModifier from './modifier/JsonStrikeModifier.svelte';
    import JsonSubscriptModifier from './modifier/JsonSubscriptModifier.svelte';
    import JsonSuperscriptModifier from './modifier/JsonSuperscriptModifier.svelte';
    import JsonUnderlineModifier from './modifier/JsonUnderlineModifier.svelte';

    export let nodes: ModifierNode[];

    const modifierMap = {
        bold: JsonBoldModifier,
        code: JsonCodeModifier,
        empty: JsonEmptyModifier,
        italic: JsonItalicModifier,
        link: JsonLinkModifier,
        image: JsonImageModifier,
        strike: JsonStrikeModifier,
        subscript: JsonSubscriptModifier,
        superscript: JsonSuperscriptModifier,
        underline: JsonUnderlineModifier
    };
</script>

<template>
    {#each nodes as node}<svelte:component this={modifierMap[node.modifier.type]} modifier={node.modifier} on:link
            >{#if node.children.length}<svelte:self nodes={node.children} />{:else}{node.text}{/if}</svelte:component
        >{/each}
</template>
