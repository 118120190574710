<script lang="ts">
    import type { DomReferenceResponseNode } from '../query';
    export let value: DomReferenceResponseNode;
</script>

<template>
    <a class="knowbl--dom-reference-bubble" href="{value.url}/#{value.id}">Read more</a>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--dom-reference-bubble) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        font-family: var(--knowbl-var-text-font-family);
        text-decoration: underline;
        cursor: pointer;
    }
</style>
