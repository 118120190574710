<script lang="ts">
    import type { Config } from '../config';
    import type { Bridge } from '../bridge';

    import { createEventDispatcher, onMount } from 'svelte';
    import { flip } from 'svelte/animate';

    import type { Bubble } from '../bubble';
    import SpeechBubble from './SpeechBubble.svelte';

    export let config: Config;
    export let bridge: Bridge;
    export let bubbles: Array<Bubble>;
    export let isChildOfBody: boolean;

    let divRef: HTMLDivElement;
    let mounted = false;

    onMount(() => {
        mounted = true;
    });

    $: if (bubbles.length && mounted) {
        divRef.scrollTop = 0;
    }

    const dispatch = createEventDispatcher();
</script>

<template>
    <div
        bind:this={divRef}
        class="knowbl--speech-list-container"
        class:knowbl--speech-list-overscroll={isChildOfBody}
        data-testid="bubbles"
    >
        {#each bubbles as bubble (bubble.id)}
            <div animate:flip={{ duration: 250 }}>
                <SpeechBubble
                    on:action
                    value={bubble}
                    {config}
                    {bridge}
                    on:link={(e) => dispatch('link', { ...e.detail, bubble })}
                />
            </div>
        {/each}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--speech-list-container) {
        padding-bottom: 1em;
        overflow-y: scroll;
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;

        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-list-overscroll) {
        overscroll-behavior: contain;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-list-container::-webkit-scrollbar) {
        display: none;
    }
</style>
