<script lang="ts">
    import type { JsonLinkNode, JsonTextNode } from '../jsonNode';
    import JsonText from './json/JsonText.svelte';
    import JsonLink from './json/JsonLink.svelte';
    import type { TermsResponseNode } from '../query';
    export let value: TermsResponseNode;

    const buildText = (text: string): JsonTextNode => ({
        type: 'hierarchy_text',
        value: text
    });

    const buildLink = ({ label, url }: { label: string; url: string }): JsonLinkNode => ({
        type: 'hierarchy_link',
        url,
        label
    });
</script>

<template>
    <div class="knowbl--terms-bubble">
        {#each value.texts as text}
            <JsonText value={buildText(text)} />
        {/each}
        <div class="knowbl--terms-link-list">
            {#each value.links as link}
                <JsonLink value={buildLink(link)} />
            {/each}
        </div>
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--terms-bubble),
    :global(#knowbl-chat-widget) :global(.knowbl--terms-bubble) :global(*) {
        color: var(--knowbl-var-color-input-text);
        font-family: var(--knowbl-var-text-font-family);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--terms-link-list) {
        display: flex;
        flex-direction: column;
        padding: 1em 0;
    }
</style>
