<script lang="ts">
    /**
     * FooterParagraph is very similar to json/JSONParagraph however the styling
     * for the footer differs from that of a chat bubble (what json/JSONParagraph is used for).
     *
     * Rather than extract out the commonalities with some layer of abstraction, I chose to duplicate
     * the template since it is easier to understand. Also the v2 chat widget will likely make this
     * unnecessary.
     */
    import { nestModifiers, type JsonParagraphNode } from '../jsonNode';
    import JsonModifiers from './json/JsonModifiers.svelte';
    export let value: JsonParagraphNode;

    const nodes = nestModifiers(value.value, value.modifiers ?? []);
</script>

<template>
    {#if nodes.length}
        <p class="knowbl--json-footer-paragraph"><JsonModifiers {nodes} on:link /></p>
    {:else}
        <p class="knowbl--json-footer-paragraph">{@html value.value}</p>
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) :global(a) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        text-decoration: underline;
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) {
        font-family: var(--knowbl-var-text-font-family);
        color: var(--knowbl-var-bubble-color-concierge-text);
        font-size: 12px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) :global(*) {
        overflow-wrap: break-word;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) :global(i) {
        font-style: italic;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) :global(em) {
        font-style: italic;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) :global(b) {
        font-weight: bold;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-footer-paragraph) :global(strong) {
        font-weight: bold;
    }
</style>
