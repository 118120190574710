<script lang="ts">
    import type { QueryBestSegment } from '../query';

    export let bestSegment: QueryBestSegment;
</script>

<template>
    <div class="knowbl--search-response-segment">
        {bestSegment.prefix}
        <b>{bestSegment.segment}</b>
        {bestSegment.suffix}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--search-response-segment) {
        color: black;
        font-size: 14px;
        font-family: var(--knowbl-var-text-font-family);
        padding-top: 0.5em;
    }
</style>
