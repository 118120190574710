import { v4 } from 'uuid';
import { BridgeConsumeType, type Bridge } from './bridge';
import { BubbleSource, getLastBubble, getLastSystemBubble, type Bubble } from './bubble';
import type { Config } from './config';
import type { JSONValue } from './types';

// Increment with any structural change to the session store
const CurrentVersion = 6;

const DefaultLocalStorageKey = 'knowbl:chat:user';
const DefaultSessionStorageKey = 'knowbl:chat:history';

export interface ChatSessionState {
    lastTurnId: string | null;
    backTurnId: string | null;
    lastUserAction: string | null;
    appKey: string;
    convStart: string;
    opened: boolean;
    maximized: boolean;
    bubbles: Bubble[];
    version: number;
    pipelineConvId: string | null;
}

export interface ChatLocalState {
    userId: string;
    prefersMute: boolean;
    consentedToTerms: boolean;
}

export const ChatHistorySymbol = Symbol();

export class ChatHistory {
    private version: number;
    private sessionStorageKey: string;
    private localStorageKey: string;

    public voiceDisabled = false;
    private bridge: Bridge;

    constructor(bridge: Bridge) {
        this.bridge = bridge;
    }

    public load(config: Config) {
        this.sessionStorageKey = config.sessionStorageKey || DefaultSessionStorageKey;
        if (!this.loadSession(this.sessionStorageKey)) {
            this.fullReset(config);
        }
        this.appKey = config.appKey;

        this.localStorageKey = config.localStorageKey || DefaultLocalStorageKey;
        this.loadLocal(this.localStorageKey);
    }

    public fullReset = (config: Config) => {
        this.stateReset(false);
        this._appKey = config.appKey;
        this._maximized = config.window.startMaximized;
        this._opened = config.window.startOpened;
        this._prefersMute = config.window.preferMute;
        this.version = CurrentVersion;
        this.saveSession();
    };

    public stateReset = (save = true) => {
        this._bubbles = [];
        this._lastTurnId = null;
        this._backTurnId = null;
        this._pipelineConvId = null;
        this._lastUserAction = null;
        this._convStart = this.currentTime();
        if (save) {
            this.saveSession();
        }
    };

    public reinit = () => {
        this._prefersMute = false;
        this._consentedToTerms = false;
        this.saveLocal();
    };

    private loadSession(key: string): boolean {
        const previousState = sessionStorage.getItem(key);
        if (previousState) {
            const loaded = JSON.parse(previousState) as ChatSessionState;

            this._lastTurnId = loaded.lastTurnId;
            this._backTurnId = loaded.backTurnId;
            this._appKey = loaded.appKey;
            this._convStart = loaded.convStart;
            this._opened = loaded.opened;
            this._maximized = loaded.maximized;
            this._bubbles = loaded.bubbles;
            this._lastUserAction = loaded.lastUserAction;
            this._pipelineConvId = loaded.pipelineConvId;

            this.version = loaded.version;

            // Clear the session if the version is out-of-date
            if (loaded.version && loaded.version === CurrentVersion) {
                return true;
            }
        }
        return false;
    }

    private loadLocal(key: string) {
        const previousState = localStorage.getItem(key) || '{}';
        const loaded = JSON.parse(previousState) as ChatLocalState;

        this._userId = loaded.userId ?? v4();
        this._prefersMute = loaded.prefersMute ?? false;
        this._consentedToTerms = loaded.consentedToTerms ?? false;

        this.saveLocal();
    }

    private currentTime = () => {
        return new Date().toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit'
        });
    };

    private saveSession = (throwIfFails = false) => {
        try {
            sessionStorage.setItem(
                this.sessionStorageKey,
                JSON.stringify({
                    lastTurnId: this._lastTurnId,
                    backTurnId: this._backTurnId,
                    lastUserAction: this._lastUserAction,
                    opened: this._opened,
                    maximized: this._maximized,
                    appKey: this._appKey,
                    convStart: this._convStart,
                    bubbles: this._bubbles,
                    version: this.version,
                    pipelineConvId: this._pipelineConvId
                })
            );
        } catch (error) {
            // we ran out of session storage
            if (throwIfFails) {
                // if we fail a 2nd time (called below), truly throw the error b/c something catastrophic happened
                throw error;
            }
            const errorMessage = `Failed to save session: ${(error as Error).message ?? 'unknown reason'}`;
            this.bridge._sendConsumable(BridgeConsumeType.error, { message: errorMessage });
            // reset the state but don't save (i.e. don't recursively call this method)
            this.stateReset(false);
            // save (call method itself) but throw if it fails this time
            this.saveSession(true);
        }
    };

    private saveLocal = () => {
        localStorage.setItem(
            this.localStorageKey,
            JSON.stringify({
                userId: this._userId,
                prefersMute: this._prefersMute,
                consentedToTerms: this._consentedToTerms
            })
        );
    };

    get convId(): string | null {
        return this._pipelineConvId ?? this.lastSystemBubble?.response?.conv ?? null;
    }

    private _lastTurnId: string | null;
    get lastTurnId() {
        return this._lastTurnId;
    }
    set lastTurnId(lastTurnId: string) {
        this._lastTurnId = lastTurnId;
        this.saveSession();
    }

    private _backTurnId: string | null;
    get backTurnId() {
        return this._backTurnId;
    }
    set backTurnId(backTurnId: string) {
        this._backTurnId = backTurnId;
        this.saveSession();
    }

    private _pipelineConvId: string | null;
    get pipelineConvId() {
        return this._pipelineConvId;
    }
    set pipelineConvId(pipelineConvId: string | null) {
        this._pipelineConvId = pipelineConvId;
        this.saveSession();
    }

    private _lastUserAction: string | null;
    get lastUserAction() {
        return this._lastUserAction;
    }
    set lastUserAction(lastUserAction: string) {
        this._lastUserAction = lastUserAction;
        this.saveSession();
    }

    private _appKey: string;
    get appKey() {
        return this._appKey;
    }
    set appKey(appKey: string) {
        if (this._appKey && this._appKey !== appKey) {
            this.stateReset();
        }
        this._appKey = appKey;
        this.saveSession();
    }

    private _opened: boolean;
    get opened() {
        return this._opened;
    }
    set opened(opened: boolean) {
        this._opened = opened;
        this.saveSession();
    }

    private _maximized: boolean;
    get maximized() {
        return this._maximized;
    }
    set maximized(maximized: boolean) {
        this._maximized = maximized;
        this.saveSession();
    }

    private _convStart: string;
    get convStart() {
        return this._convStart;
    }
    set convStart(convStart: string) {
        this._convStart = convStart;
        this.saveSession();
    }

    private _bubbles: Array<Bubble>;
    get bubbles() {
        return this._bubbles;
    }
    set bubbles(bubbles: Bubble[]) {
        this._bubbles = bubbles;
        this.saveSession();
    }
    expandBubble = (id: string) => {
        const bubble = this._bubbles.find((b) => b.id === id);
        bubble.is_expanded = true;
        this.saveSession();
    };
    addBubble = (bubble: Bubble) => {
        this._bubbles.unshift(bubble);
        this.saveSession();
    };
    get lastSystemBubble(): Bubble | null {
        return getLastSystemBubble(this._bubbles);
    }
    get lastBubble(): Bubble | null {
        return getLastBubble(this._bubbles);
    }
    private bubbleToJson = (bubble: Bubble): JSONValue => {
        return {
            id: bubble.response.turn,
            request: bubble.request as unknown as JSONValue,
            response: bubble.response as unknown as JSONValue
        };
    };
    public exportLatestQuery = (): JSONValue => {
        return this.bubbleToJson(getLastSystemBubble(this._bubbles));
    };
    public exportConversation = (): JSONValue => {
        return this._bubbles
            .filter((bubble) => bubble.source === BubbleSource.system && bubble.response !== null)
            .map((bubble) => this.bubbleToJson(bubble));
    };

    private _userId: string;
    get userId() {
        return this._userId;
    }
    private set userId(userId: string) {
        this._userId = userId;
        this.saveLocal();
    }

    private _prefersMute: boolean;
    get prefersMute() {
        return this._prefersMute;
    }
    set prefersMute(prefersMute: boolean) {
        this._prefersMute = prefersMute;
        this.saveLocal();
    }

    private _consentedToTerms: boolean;
    get consentedToTerms() {
        return this._consentedToTerms;
    }
    set consentedToTerms(consentedToTerms: boolean) {
        this._consentedToTerms = consentedToTerms;
        this.saveLocal();
    }
}
