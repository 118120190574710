<script lang="ts">
    import type { Config } from '../config';

    import { createEventDispatcher, getContext, onMount } from 'svelte';
    import { fade, fly } from 'svelte/transition';

    import JsonSpeechBubble from './JsonSpeechBubble.svelte';

    import type { Writable } from 'svelte/store';
    import type { Bubble } from '../bubble';
    import type { LinkEvent } from '../event';
    import { ResponseNodeType, type QueryAltResponse } from '../query';
    import { ChatHistorySymbol, type ChatHistory } from '../session';
    import SubjectLink from './SubjectLink.svelte';
    import TermsSpeechBubble from './TermsSpeechBubble.svelte';
    import SearchResponseSegment from './SearchResponseSegment.svelte';

    export let config: Config;
    export let bubble: Bubble | null;
    export let isChildOfBody: boolean;

    const dispatch = createEventDispatcher();

    const maxResponseLength = 75;

    const truncateText = (text: string | null) => {
        if (text === null) {
            return null;
        }
        if (text.length < maxResponseLength) {
            return text;
        }
        return text.slice(0, maxResponseLength).trimEnd() + '...';
    };

    $: alts = bubble?.response?.alt_responses ?? [];

    const handleAlt = (alt: QueryAltResponse) => {
        dispatch('alt', { alt });
    };

    $: expanded = bubble?.is_expanded || false;

    const chatHistory: Writable<ChatHistory> = getContext(ChatHistorySymbol);
    const expand = () => {
        expanded = true;
        $chatHistory.expandBubble(bubble.id);
    };

    let mounted = false;
    let divRef: HTMLDivElement = null;

    onMount(() => {
        mounted = true;
    });

    $: if (bubble === null && mounted) {
        divRef.scrollTop = 0;
    }

    const handleLinkClick = (event: CustomEvent<LinkEvent>, alt?: QueryAltResponse) => {
        dispatch('link', {
            ...event.detail,
            bubble,
            contentId: alt?.content_id,
            subject: alt?.subject,
            isAlt: alt !== undefined
        } as LinkEvent);
    };
</script>

<template>
    <div
        bind:this={divRef}
        class="knowbl--search-container"
        class:knowbl--search-overscroll={isChildOfBody}
        data-testid="bubbles"
    >
        {#if bubble !== null && bubble.source === 'system'}
            <div class="knowbl--search-primary" in:fade|local={{ duration: 300 }} out:fade|local={{ duration: 350 }}>
                <SubjectLink
                    source={bubble?.response?.response.source}
                    text={bubble?.response?.response.subject}
                    {bubble}
                    on:link={handleLinkClick}
                />
                {#if !expanded && bubble?.response?.response.best_segment}
                    <SearchResponseSegment bestSegment={bubble?.response?.response.best_segment} />
                    <div class="knowbl--search-divider" />
                    <button class="knowbl--search-read-more" aria-label="Read more" on:click={expand}>Read More</button>
                {:else if expanded && bubble.expanded_value?.type === ResponseNodeType.json}
                    <JsonSpeechBubble
                        on:action
                        value={bubble.expanded_value}
                        {config}
                        latest={true}
                        on:link={handleLinkClick}
                    />
                {:else if bubble.value.type === ResponseNodeType.json}
                    <JsonSpeechBubble on:action value={bubble.value} {config} latest={true} on:link={handleLinkClick} />
                    {#if bubble.expanded_value}
                        <div class="knowbl--search-divider" />
                        <button class="knowbl--search-read-more" aria-label="Read more" on:click={expand}
                            >Read More</button
                        >
                    {/if}
                {:else if bubble.value.type === ResponseNodeType.terms}
                    <TermsSpeechBubble on:action value={bubble.value} />
                {/if}
            </div>
        {:else if bubble !== null && bubble.source === 'error' && bubble.value.type === ResponseNodeType.json}
            <div class="knowbl--search-error" in:fade|local={{ duration: 300 }} out:fade|local={{ duration: 350 }}>
                <JsonSpeechBubble on:action value={bubble.value} {config} latest={true} on:link={handleLinkClick} />
            </div>
        {/if}
        {#each alts as alt, i}
            <div
                class="knowbl--search-alt-container"
                in:fly|local={{ delay: 100 * (i + 1), duration: 300, x: 500 }}
                out:fade|local={{ delay: 25 * (bubble.response.alt_responses.length - i), duration: 100 }}
            >
                <button class="knowbl--search-alt" aria-label="Click {alt.subject}" on:click={() => handleAlt(alt)}>
                    <SubjectLink
                        source={alt.source}
                        text={alt.subject}
                        {bubble}
                        on:link={(e) => handleLinkClick(e, alt)}
                    />
                    <div class="knowbl--search-alt-text">
                        {#if alt.best_segment}
                            <SearchResponseSegment bestSegment={alt.best_segment} />
                        {:else}
                            {truncateText(alt.response_text)}
                        {/if}
                    </div>
                </button>
            </div>
        {/each}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--search-container) {
        padding-bottom: 1em;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-overscroll) {
        overscroll-behavior: contain;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-container::-webkit-scrollbar) {
        display: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-primary) {
        padding: 1.5em;
        margin: 1.5em;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
        border-radius: 10px;
        border: 3px solid var(--knowbl-var-window-accent-color);
        background-color: white;
        display: flex;
        flex-direction: column;
        color: black;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-error) {
        padding: 0.5em;
        margin: 1.5em;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
        border: 3px solid #e72020;
        background-color: rgb(255, 188, 188);
        display: flex;
        flex-direction: column;
        color: black;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-alt-container) {
        margin: 0.5em 2em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-alt) {
        padding: 1.5em;

        border-radius: 10px;
        border: 3px solid #00000077;
        background-color: #ffffff;
        opacity: 0.7;
        transition: opacity 0.2s ease;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-alt) :global(*) {
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-alt:hover) {
        opacity: 1;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-alt-heading) {
        color: #1f43e4;
        font-size: 14px;
        font-weight: bold;
        font-family: var(--knowbl-var-text-font-family);
        margin-bottom: 1em;
        pointer-events: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-alt-text) {
        color: black;
        font-size: 14px;
        font-family: var(--knowbl-var-text-font-family);
        pointer-events: none;
        padding-top: 0.5em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-divider) {
        width: 100%;
        height: 1px;
        margin: 1em 0;
        border-bottom: 1px solid black;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--search-read-more) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        font-family: var(--knowbl-var-text-font-family);
        text-decoration: underline;
        cursor: pointer;
    }
</style>
