<script lang="ts">
    import type { SpeechRecorder } from '../recorder';

    import { onMount, onDestroy } from 'svelte';

    export let recorder: SpeechRecorder;

    let visualizer: SVGElement;
    let paths: HTMLCollectionOf<Element>;
    let mask: SVGMaskElement;
    let frameID: number;
    const totalSamples = 64; // Should be a multiple of 8 less than 1024

    onMount(() => {
        paths = document.getElementsByClassName('knowbl--wave-path');

        const bitCount = recorder.getBitCount();
        if (!bitCount) {
            return;
        }
        const compressionRate = bitCount / totalSamples;

        const frequencyArray = new Uint8Array(bitCount);
        visualizer.setAttribute('viewBox', `0 0 ${totalSamples} ${totalSamples}`);

        for (let i = 0; i < totalSamples; i++) {
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            path.classList.add('knowbl--wave-path');
            mask.appendChild(path);
        }

        const doDraw = function () {
            frameID = requestAnimationFrame(doDraw);
            recorder.getFreqData(frequencyArray);
            for (let i = 0; i < totalSamples; i++) {
                let total = 0;
                for (let j = 0; j < compressionRate; j++) {
                    total += frequencyArray[i * compressionRate + j];
                }
                const adjustedLength = Math.floor(total / (compressionRate * 2));
                paths[i].setAttribute('d', `M ${i},${totalSamples} l 0,-${adjustedLength}`);
            }
        };

        doDraw();
    });

    onDestroy(() => {
        window.cancelAnimationFrame(frameID);
    });
</script>

<template>
    <svg preserveAspectRatio="none" bind:this={visualizer} class="knowbl--wave-svg">
        <defs>
            <mask id="knowbl--wave-mask" bind:this={mask} />
        </defs>
        <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="var(--knowbl-var-window-accent-color)"
            mask="url(#knowbl--wave-mask)"
        />
    </svg>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--wave-svg) {
        width: 100%;
        height: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--wave-svg) :global(path) {
        stroke-linecap: round;
        stroke: white;
        stroke-width: 0.9px;
    }
</style>
