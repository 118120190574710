<script lang="ts">
    import type { JsonImageNode, JsonLinkNode } from '../../../jsonNode';
    import type { Config } from '../../../config';

    import JsonImage from '../JsonImage.svelte';
    import JsonCardLink from './JsonCardLink.svelte';

    export let config: Config;
    export let images: JsonImageNode[];
    export let maybeLink: JsonLinkNode | undefined;

    // only use the first 5 images with urls
    images = images.filter((i: JsonImageNode) => !!i.url).slice(0, 5);

    // preload the images for smooth UI experience
    images.forEach((i) => {
        const image = new Image();
        image.src = i.url;
    });

    let visibleImageIndex = 0;
    const prevImage = () => {
        visibleImageIndex -= 1;
        if (visibleImageIndex < 0) {
            visibleImageIndex = images.length - 1;
        }
    };

    const nextImage = () => {
        visibleImageIndex += 1;
        if (visibleImageIndex === images.length) {
            visibleImageIndex = 0;
        }
    };
    /* eslint-disable @typescript-eslint/no-unused-vars */
</script>

<template>
    <div class="knowbl--card-image-pane">
        {#each images as image, index}
            {#if visibleImageIndex === index}
                <JsonImage value={image} {config} />
            {/if}
        {/each}
        <JsonCardLink value={maybeLink} on:link />
    </div>
    {#if images.length > 1}
        <div class="knowbl--card-image-button-group">
            {#each images as _, index}
                {#if visibleImageIndex === index}
                    <button class="knowbl--card-image-index-button active" aria-label="Show image" />
                {:else}
                    <button
                        class="knowbl--card-image-index-button"
                        aria-label="Show image"
                        on:click={() => (visibleImageIndex = index)}
                    />
                {/if}
            {/each}
        </div>
        <div class="knowbl--card-image-button-group">
            <button class="knowbl--card-image-button" aria-label="Show previous image" on:click={prevImage}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                </svg>
            </button>
            <button class="knowbl--card-image-button" aria-label="Show next image" on:click={nextImage}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.0"
                    stroke="black"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
            </button>
        </div>
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--card) :global(.knowbl--card-image-pane) {
        display: flex;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-image-button-group) {
        width: 100%;
        text-align: center;
        margin-bottom: 4px;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-image-index-button) {
        background-color: lightgray;
        height: 6px;
        width: 22px;
        border-radius: 10px;
        margin-right: 5px;
        cursor: pointer;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-image-index-button.active) {
        background-color: black;
        cursor: default;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-image-button) {
        background-color: lightgray;
        border-radius: 5px;
        cursor: pointer;
        padding-right: 8px;
        padding-left: 8px;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-image-button svg) {
        height: 12px;
        width: 18px;
        color: #1a1a1a !important;
        stroke: currentColor;
    }
</style>
