<script lang="ts">
    import type { Config } from '../config';
    import { JsonNodeType } from '../jsonNode';
    import FooterParagraph from './FooterParagraph.svelte';

    export let config: Config;
</script>

<template>
    {#if config.footerPrefix || config.footerLinks}
        <div class="knowbl--chat-footer">
            {#if config.footerPrefix}
                {config.footerPrefix}
            {/if}
            {#if config.footerLinks}
                {#each config.footerLinks as { label, url }, index}
                    <a class="knowbl--var-bubble-color-concierge-link-text" href={url}>{label}</a>
                    {#if index + 1 !== config.footerLinks.length}
                        {' | '}
                    {/if}
                {/each}
            {/if}
        </div>
    {/if}
    {#if config.footer}
        <div class="knowbl--chat-footer">
            {#each config.footer as child}
                {#if child.type === JsonNodeType.paragraph}
                    <FooterParagraph value={child} on:link />
                {/if}
            {/each}
        </div>
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--chat-footer) {
        font-family: var(--knowbl-var-text-font-family);
        color: var(--knowbl-var-bubble-color-concierge-text);
        font-size: 14px;
        padding-top: 2px;
        padding-bottom: 4px;
        text-align: center;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--chat-footer) :global(a) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        text-decoration: underline;
        cursor: pointer;
    }
</style>
