<script lang="ts">
    import type { JsonModifier } from '../../../jsonNode';

    // svelte-ignore unused-export-let
    export let modifier: JsonModifier;
</script>

<template>
    <em><slot /></em>
</template>
