<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { EventAction } from '../../../action';
    import JsonActionButton from './JsonActionButton.svelte';
    export let value: EventAction;

    let disabled = false;

    const dispatch = createEventDispatcher();
    const handleAction = () => {
        dispatch('action', { action: value });
        disabled = true;
    };
</script>

<template>
    <JsonActionButton {disabled} on:action={handleAction} label={value.label} />
</template>
