export type Action = QueryAction | EventAction | TermsAction;

export enum ActionType {
    query = 'query',
    event = 'event',
    terms = 'terms'
}

export class QueryAction {
    type: 'query';
    label: string;
    utterance: string;
}

export class EventAction {
    type: 'event';
    label: string;
    event: string;
}

export class TermsAction {
    type: 'terms';
}
