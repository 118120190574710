<script lang="ts">
    import type { HtmlResponseNode } from '../query';
    export let value: HtmlResponseNode;
</script>

<template>
    <div class="knowbl--raw-html-bubble">{@html value.value}</div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble),
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(*) {
        color: var(--knowbl-var-color-input-text);
        font-family: var(--knowbl-var-text-font-family);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(a) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        text-decoration: underline;
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(img) {
        width: 100%;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        max-height: 300px;
        object-fit: contain;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(p) {
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(ul),
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(li) {
        margin-inline-start: 0.4em;
        padding-inline-start: 0.1em;
        padding-block: 0.2em 0.2em;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(ul) :global(p),
    :global(#knowbl-chat-widget) :global(.knowbl--raw-html-bubble) :global(li) {
        padding-top: 0;
        padding-bottom: 0;
    }
</style>
