<script lang="ts">
    import type { JsonImageModifer, JsonModifier } from '../../../jsonNode';
    export let modifier: JsonModifier;

    const castedModifier = modifier as JsonImageModifer;
    const width = Math.min(castedModifier.width ?? 32, 32);
</script>

<template>
    <img class="knowbl--json-inline-image" src={castedModifier.src} alt={castedModifier.alt} height="auto" {width} />
    <!-- this is not used but removes a warning -->
    <slot />
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-inline-image) {
        max-height: 64px;
        object-fit: contain;
        vertical-align: middle;
        display: inline-block;
        padding: 0.2em;
    }
</style>
