<script lang="ts">
    import { createEventDispatcher } from 'svelte';

    import type { JsonActionsNode } from '../../../jsonNode';
    import type { DispatchOptions } from 'svelte/internal';
    import JsonCardActionButton from './JsonCardActionButton.svelte';
    import type { EventAction, QueryAction } from '../../../action';

    export let value: JsonActionsNode | undefined;
    export let latest: boolean;
    export let dispatch: <EventKey extends Extract<keyof any, string>>(
        type: EventKey,
        detail?: any,
        options?: DispatchOptions
    ) => boolean | null;

    dispatch = dispatch || createEventDispatcher();

    const action1 = value?.actions[0] as undefined | EventAction | QueryAction;
    const action2 = value?.actions[1] as undefined | EventAction | QueryAction;

    export const buttonStyle = action2 ? 'width: 42%;' : 'width: 95%;';
</script>

<template>
    {#if action1}
        <div class="knowbl--card-actions">
            <JsonCardActionButton
                on:action
                style={buttonStyle}
                disabled={!latest}
                value={action1}
                inverse={false}
                {dispatch}
            />
            {#if action2}
                <JsonCardActionButton
                    on:action
                    style={buttonStyle}
                    disabled={!latest}
                    value={action2}
                    inverse={true}
                    {dispatch}
                />
            {/if}
        </div>
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--card-actions) {
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        display: flex;
        align-items: stretch;
        column-gap: 0.25rem;
    }
</style>
