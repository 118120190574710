<script lang="ts">
    import { ActionType } from '../../../action';
    import type { EventAction, QueryAction } from '../../../action';
    import { createEventDispatcher } from 'svelte';
    import type { DispatchOptions } from 'svelte/internal';

    export let value: QueryAction | EventAction | undefined;
    export let disabled: boolean;
    export let inverse: boolean;
    export let style: string;
    export let dispatch: <EventKey extends Extract<keyof any, string>>(
        type: EventKey,
        detail?: any,
        options?: DispatchOptions
    ) => boolean | undefined;

    dispatch = dispatch || createEventDispatcher();

    const handleClick = () => {
        dispatch('action', { action: value });
        if (value.type === ActionType.event) {
            disabled = true;
        }
    };
</script>

<template>
    {#if inverse}
        <button
            {style}
            class="knowbl--card-actions-button-inverse"
            aria-label="Click {value.label}"
            on:click={() => handleClick()}
            {disabled}>{value.label}</button
        >
    {:else}
        <button
            {style}
            class="knowbl--card-actions-button"
            aria-label="Click {value.label}"
            on:click={() => handleClick()}
            {disabled}>{value.label}</button
        >
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--card-actions-button) {
        color: black;
        border: 1px solid black;
        padding: 4px 8px 4px 8px;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--card-actions-button-inverse) {
        color: white;
        background-color: black;
        border: 1px solid black;
        padding: 4px 8px 4px 8px;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
    }
</style>
