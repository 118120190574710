<script lang="ts">
    import type { Config } from '../config';

    import { getContext } from 'svelte';
    import type { Writable } from 'svelte/store';
    import { Bubble, BubbleSource, RequestType } from '../bubble';
    import type { Bridge } from '../bridge';
    import { ResponseNodeType } from '../query';
    import { ChatHistorySymbol, type ChatHistory } from '../session';
    import { postFeedback } from '../analytics';
    import DomReferenceSpeechBubble from './DomReferenceSpeechBubble.svelte';
    import HtmlSpeechBubble from './HtmlSpeechBubble.svelte';
    import JsonSpeechBubble from './JsonSpeechBubble.svelte';
    import ListSpeechBubble from './ListSpeechBubble.svelte';
    import TermsSpeechBubble from './TermsSpeechBubble.svelte';

    export let value: Bubble;
    export let config: Config;
    export let bridge: Bridge;

    const chatHistory: Writable<ChatHistory> = getContext(ChatHistorySymbol);
    let expanded = value.is_expanded || false;

    let feedbackSubmitted = false;
    $: latest = $chatHistory?.lastBubble === value;
    $: linkButtonUrl = config.bubbles?.contentSourceLinks?.enabled ? value.response?.response?.source?.location : null;

    const expand = () => {
        expanded = true;
        $chatHistory.expandBubble(value.id);
    };

    const handleFeedbackClick = async (score: number) => {
        feedbackSubmitted = true;
        await postFeedback(config, bridge, $chatHistory.userId, $chatHistory.lastTurnId, $chatHistory.appKey, score);
    };
</script>

<template>
    <div class="knowbl--speech-bubble-container knowbl--speech-bubble-{value.source.toString()}">
        <div class="knowbl--speech-bubble-label knowbl--speech-bubble-label-{value.source.toString()}">
            {#if value.source === BubbleSource.user}
                You
            {:else if value.source === BubbleSource.system}
                {config.concierge.name}
            {:else}
                Error
            {/if}
        </div>
        <div class="knowbl--speech-bubble">
            <div class="knowbl--speech-bubble-content">
                {#if !expanded}
                    {#if value.value.type === ResponseNodeType.html}
                        <HtmlSpeechBubble value={value.value} />
                    {:else if value.value.type === ResponseNodeType.json}
                        <!-- only pass the link button location if expanded -->
                        <JsonSpeechBubble
                            on:action
                            value={value.value}
                            linkButtonUrl={value.expanded_value ? null : linkButtonUrl}
                            {config}
                            {latest}
                            on:link
                        />
                    {:else if value.value.type === ResponseNodeType.list}
                        <ListSpeechBubble value={value.value} />
                    {:else if value.value.type === ResponseNodeType.domReference}
                        <DomReferenceSpeechBubble value={value.value} />
                    {:else if value.value.type === ResponseNodeType.terms}
                        <TermsSpeechBubble on:action value={value.value} />
                    {/if}
                    {#if value.expanded_value}
                        <div class="knowbl--speech-bubble-divider" />
                        <button class="knowbl--speech-bubble-read-more" aria-label="Read more" on:click={expand}
                            >Read More</button
                        >
                    {/if}
                {:else if value.expanded_value.type === ResponseNodeType.json}
                    <JsonSpeechBubble
                        on:action
                        value={value.expanded_value}
                        {linkButtonUrl}
                        {config}
                        {latest}
                        on:link
                    />
                {/if}
            </div>
        </div>
        {#if latest && value.source === BubbleSource.system && value.request_type === RequestType.query && config.bubbles.feedbackEnabled && !feedbackSubmitted}
            <div class="knowbl--thumb-container">
                <div class="knowbl--thumb-label">Was this helpful?</div>
                <button class="knowbl--thumb-button" on:click={() => handleFeedbackClick(1)}>
                    <svg class="knowbl--thumb-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                                d="M14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3.482a1 1 0 0 0 .817-.423L11.752.85a.5.5 0 0 1 .632-.159l1.814.907a2.5 2.5 0 0 1 1.305 2.853L14.6 8zM7 10.588V19h11.16L21 12.104V10h-6.4a2 2 0 0 1-1.938-2.493l.903-3.548a.5.5 0 0 0-.261-.571l-.661-.33-4.71 6.672c-.25.354-.57.644-.933.858zM5 11H3v8h2v-8z"
                            />
                        </g>
                    </svg>
                </button>

                <button class="knowbl--thumb-button" on:click={() => handleFeedbackClick(-1)}>
                    <svg class="knowbl--thumb-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                                d="M9.4 16H3a2 2 0 0 1-2-2v-2.104a2 2 0 0 1 .15-.762L4.246 3.62A1 1 0 0 1 5.17 3H22a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3.482a1 1 0 0 0-.817.423l-5.453 7.726a.5.5 0 0 1-.632.159L9.802 22.4a2.5 2.5 0 0 1-1.305-2.853L9.4 16zm7.6-2.588V5H5.84L3 11.896V14h6.4a2 2 0 0 1 1.938 2.493l-.903 3.548a.5.5 0 0 0 .261.571l.661.33 4.71-6.672c.25-.354.57-.644.933-.858zM19 13h2V5h-2v8z"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        {:else if feedbackSubmitted}
            <div class="knowbl--thumb-container">
                <div class="knowbl--thumb-label">Thank you for providing feedback!</div>
            </div>
        {/if}
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--thumb-container) {
        margin: 0 0.75em;
        font-family: var(--knowbl-var-text-font-family);
        display: flex;
        align-items: center;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--thumb-label) {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 1px;
        color: white;
        font-family: var(--knowbl-var-text-font-family);
        margin: 0.5em 0.75em;
        display: inline-block;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--thumb-button) {
        cursor: pointer;
    }

    :global(#knowbl-chat-widget) :global(.knowbl--thumb-svg) {
        fill: #cccccc; /* Lighter color to match lighter font weight of knowbl--thumb-label */
        height: 1.25em;
        width: 1.25em;
        margin: 0em 0.25em;
        transition: all 0.2s ease; /* Smooth transition for size and color change */
    }

    :global(#knowbl-chat-widget) :global(.knowbl--thumb-svg:hover) {
        fill: white;
        height: 1.5em; /* Increase size */
        width: 1.5em; /* Increase size */
        stroke: black; /* Adds a stroke (outline) to the SVG paths */
        stroke-width: 0.1; /* Width of the stroke, adjust as needed */
    }

    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-container) {
        display: inline-block;
        font-family: var(--knowbl-var-text-font-family);
        margin: 0 0.75em;
        max-width: 85%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-label) {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        color: white;
        font-family: var(--knowbl-var-text-font-family);
        margin: 1em 0.75em;
        text-transform: uppercase;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-label-user) {
        text-align: right;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble) {
        max-width: var(--knowbl-var-bubble-max-width);
        padding: 1.5em;
        margin: 0.5em;
        border-radius: 10px;
        border: 1px solid #979797;
        background-color: rgba(255, 255, 255, 0.13);
        display: flex;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-content) {
        max-width: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-user) {
        float: right;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-divider) {
        width: 100%;
        height: 1px;
        margin: 1em 0;
        border-bottom: 1px solid var(--knowbl-var-bubble-color-concierge-text);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--speech-bubble-read-more) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        font-family: var(--knowbl-var-text-font-family);
        text-decoration: underline;
        cursor: pointer;
    }
</style>
