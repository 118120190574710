const INT_16_MAX = 32767;

export enum Status {
    on = 'on',
    off = 'off',
    handshake = 'handshake',
    failed = 'failed'
}

enum MessageStatus {
    open = 'open',
    transcript = 'transcript',
    error = 'error',
    handshake = 'handshake'
}

interface Message {
    status: MessageStatus;
}

interface ErrorMessage extends Message {
    message: string;
}

interface TranscriptMessage extends Message {
    transcript: string;
    final: boolean;
    confidence: number;
}

interface ISignal<T> {
    on(handler: { (data?: T): void }): void;
    off(handler: { (data?: T): void }): void;
}

export class Signal<T> implements ISignal<T> {
    private handlers: { (data?: T): void }[] = [];

    public on(handler: { (data?: T): void }): void {
        this.handlers.push(handler);
    }

    public off(handler: { (data?: T): void }): void {
        this.handlers = this.handlers.filter((h) => h !== handler);
    }

    public trigger(data?: T) {
        this.handlers.slice(0).forEach((h) => h(data));
    }

    public expose(): ISignal<T> {
        return this;
    }
}

export class SpeechRecorder {
    private url: string;
    private appKey: string;
    private integrationKey: string;
    private extraHeaders: Record<string, string>;

    private readonly onTranscript = new Signal<string>();
    private readonly onComplete = new Signal<string>();
    private readonly onStatus = new Signal<Status>();
    private readonly onError = new Signal<string>();

    public get TranscriptReceived() {
        return this.onTranscript.expose();
    }
    public get TranscriptionCompleted() {
        return this.onComplete.expose();
    }
    public get StatusChanged() {
        return this.onStatus.expose();
    }
    public get ErrorReceived() {
        return this.onError.expose();
    }

    private socket?: WebSocket = undefined;
    private stream?: MediaStream = undefined;
    private context?: AudioContext = undefined;
    private processor?: ScriptProcessorNode = undefined;
    private source?: MediaStreamAudioSourceNode = undefined;
    private analyser?: AnalyserNode = undefined;

    private readonly stabilityCutoff: number = 0.8;
    private readonly integration: string = 'google';
    private readonly sampleRate: number = 16000;
    private locale: string;

    private readonly bufferSize = 2 ** 12;
    private readonly numInputChannels = 1;
    private readonly numOutputChannels = 1;
    private readonly fftSize = 2048;

    private currentSampleRate: number;

    public status = Status.off;
    private lastTranscript = '';
    private completed = false;

    public constructor(
        url: string,
        appKey: string,
        integrationKey: string | null,
        extraHeaders: Record<string, string>,
        locale?: string
    ) {
        this.url = url;
        this.appKey = appKey;
        this.integrationKey = integrationKey;
        this.extraHeaders = extraHeaders ?? {};
        this.locale = locale ?? 'en-US';
    }

    public async start(headers?: Record<string, string>) {
        if (this.status !== Status.off) {
            await this.stop();
            return;
        }

        this._setStatus(Status.handshake);

        if (!this.stream) {
            try {
                this.stream = await navigator.mediaDevices.getUserMedia({
                    audio: true
                });
            } catch (_) {
                this._setStatus(Status.failed);
                return;
            }
        }

        if (!this.context) {
            this.currentSampleRate = await this._initRecord();
        }

        await this.context.resume();

        this.lastTranscript = '';
        this.completed = false;

        this.socket = new WebSocket(this.url);
        this.socket.onmessage = async (event) => {
            const message = JSON.parse(event.data as string) as Message;
            switch (message.status) {
                case MessageStatus.open: {
                    this._setStatus(Status.on);
                    break;
                }
                case MessageStatus.handshake: {
                    this.socket?.send(
                        JSON.stringify({
                            pipeline_id: this.appKey,
                            integration_key: this.integrationKey,
                            sample_rate: this.currentSampleRate,
                            locale: this.locale,
                            stability_cutoff: this.stabilityCutoff,
                            extra_headers: {
                                ...(this.extraHeaders ?? {}),
                                ...(headers ?? {})
                            },
                            // Deprecated params
                            app_key: this.appKey,
                            integration: this.integration
                        })
                    );
                    break;
                }
                case MessageStatus.error: {
                    const errorMessage = message as ErrorMessage;
                    this.onError.trigger(errorMessage.message);
                    await this.stop();
                    break;
                }
                case MessageStatus.transcript: {
                    const transcriptMessage = message as TranscriptMessage;
                    this.lastTranscript = transcriptMessage.transcript;
                    this.onTranscript.trigger(transcriptMessage.transcript);
                    if (transcriptMessage.final) {
                        this._complete(transcriptMessage.transcript);
                        await this.stop();
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        };
        this.socket.onclose = async () => {
            await this.stop();
        };
        this.socket.onerror = async () => {
            this.onError.trigger('Unable to connect to ASR service.');
            await this.stop();
        };
    }

    public async stop() {
        if (this.socket && this.socket.readyState !== this.socket.CLOSED) {
            this.socket.close();
        }
        this.socket = undefined;

        this.stream?.getTracks().forEach((t) => t.stop());
        this.stream = undefined;

        await this.context?.close();
        this.context = undefined;

        this._complete(this.lastTranscript);

        if (this.status !== Status.failed) {
            this._setStatus(Status.off);
        }
    }

    private async _initRecord() {
        if (!this.stream) {
            this.onError.trigger('Microphone could not be accessed.');
            await this.stop();
            return;
        }

        try {
            this.context = new AudioContext({ sampleRate: this.sampleRate });
            this.source = this.context.createMediaStreamSource(this.stream);
            this.processor = this.context.createScriptProcessor(
                this.bufferSize,
                this.numInputChannels,
                this.numOutputChannels
            );
        } catch {
            // Some browsers do not support setting the sample rate
            this.context = new AudioContext();
            this.source = this.context.createMediaStreamSource(this.stream);
            this.processor = this.context.createScriptProcessor(
                this.bufferSize,
                this.numInputChannels,
                this.numOutputChannels
            );
        }
        this.analyser = this.context.createAnalyser();
        this.analyser.fftSize = this.fftSize;

        this.source.connect(this.processor);
        this.source.connect(this.analyser);
        this.processor.connect(this.context.destination);
        this.processor.onaudioprocess = (e) => {
            const channel = e.inputBuffer.getChannelData(0);
            if (this.status === Status.on) {
                this.socket?.send(Int16Array.from(channel, (x) => x * INT_16_MAX));
            }
        };

        return this.context.sampleRate;
    }

    public getFreqData(frequencyArray: Uint8Array) {
        this.analyser?.getByteFrequencyData(frequencyArray);
    }

    public getBitCount() {
        return this.analyser?.frequencyBinCount;
    }

    private _complete(finalTranscript: string) {
        if (this.completed || !finalTranscript) {
            return;
        }
        this.completed = true;
        this.onComplete.trigger(finalTranscript);
    }

    private _setStatus(status: Status) {
        if (this.status === status) {
            return;
        }
        this.status = status;
        this.onStatus.trigger(this.status);
    }
}
