<script lang="ts">
    import { nestModifiers, type JsonParagraphNode } from '../../jsonNode';
    import JsonModifiers from './JsonModifiers.svelte';
    export let value: JsonParagraphNode;

    const nodes = nestModifiers(value.value, value.modifiers ?? []);
</script>

<template>
    {#if nodes.length}
        <p class="knowbl--json-paragraph"><JsonModifiers {nodes} on:link /></p>
    {:else}
        <p class="knowbl--json-paragraph">{@html value.value}</p>
    {/if}
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) :global(a) {
        color: var(--knowbl-var-bubble-color-concierge-link-text);
        text-decoration: underline;
        cursor: pointer;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) {
        margin: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        overflow-wrap: break-word;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) :global(*) {
        overflow-wrap: break-word;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) :global(i) {
        font-style: italic;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) :global(em) {
        font-style: italic;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) :global(b) {
        font-weight: bold;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--json-paragraph) :global(strong) {
        font-weight: bold;
    }
</style>
